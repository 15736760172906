// Variables
// =========================


// Colors
// -------------------------

$color-brand: #39f;
$color-black: #000;
$color-white: #fff;


// Media queries breakpoints
// -------------------------

$screen-xs: 480px; // Extra small screen / phone
$screen-sm: 768px; // Small screen / tablet
$screen-md: 992px; // Medium screen / desktop
$screen-lg: 1200px; // Large screen / wide desktop
