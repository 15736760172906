/*
 * FOUNDATION OVERWRITES 
 * @author: Rianne Oosthoek
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

$body-font-size: rem-calc(14);
$global-line-height: 1.5;

$dark-primary-color: darken($primary-color, 15%);

body{font-size: $body-font-size; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; line-height:$global-line-height;}
p{font-size: $body-font-size;}
ul, ol, dl{font-size: $body-font-size;}
[type="text"], 
[type="password"], 
[type="date"], 
[type="datetime"], 
[type="datetime-local"], 
[type="month"], 
[type="week"], 
[type="email"], 
[type="number"], 
[type="search"], 
[type="tel"], 
[type="time"], 
[type="url"], 
[type="color"], 
textarea,
select{font-weight:400;}
label > [type="radio"]{margin-right:0.7rem;}

/* Header top bar */
.header-wrapper{
    .menu-anchor{
        &.main-menu-anchor{
            line-height:2.4rem;
            &:hover{background-color:$dark-primary-color;}
        }
    }    
    .submenu{
        .menu-anchor{height:4rem;}
    }
    .is-down-arrow{
        &.is-dropdown-submenu-parent .menu-anchor{
            &::after{top: 2.2rem;}
        }
    }
}

.is-down-arrow{
    &.is-dropdown-submenu-parent .menu-anchor{
        &::after{
            @include position($position: absolute, $top: 1.8rem, $right: 1.5rem, $left: auto);
            margin-top:0;
        }
    }
    & > .menu-anchor{
        padding-right:3.5rem;
        &::after{
            @include css-triangle(.5rem, $white, down);
        }
    }
}
.is-dropdown-submenu{z-index:2;}

.top-bar-right{
    @include position($position: absolute, $zindex: 1005, $top: 4rem);
    width:100%;
    background-color:$primary-color;
}

/* General menu style */
.menu{
    font-size:1.4rem;
    &.align-right{
        float:right;
        display:inline-block;
    }
    &.nested{
        margin-left:0;
        background-color:lighten($dark-gray, 5%);
        display:none;
        .menu-anchor{padding-left:2.5rem;}
    }
    .menu-anchor{
        color:$white; white-space:nowrap;
        border-left:3px solid transparent;
        transition:background-color .2s ease-in-out;
        &:hover{background-color:darken($dark-gray, 5%);}        
        &.active{
            background-color:darken($dark-gray, 5%);
            border-color:$primary-color;
        }
    }
}

/* Submenu sidebar */
.is-accordion-submenu-parent{
    &[aria-expanded="true"]{
        background-color:lighten($dark-gray, 5%);
        > .menu-anchor{
            &::after{content: "\73";}
        }
    }
    // First direct child with this class
    > .menu-anchor{
        @include position($position: relative);
        &::after{
            @include position($position:absolute, $top: 1.4rem, $right: 1rem, $left: auto);
            @extend .icon-font;
            font-size:1rem;
            content: "\6c";
        }
    }
    .menu-anchor{white-space:normal;}
}

/* Buttons */
.button-wrapper{
    .menu{margin-bottom:1rem;}
}
.button-group{
    .button:not(:last-child){border-color:$white;}
    &.tiny .icn{font-size:1.3rem; line-height:1.3;}
}
.button{
    line-height:$global-line-height;
    border:0;
}

/* Switch */
.switch-paddle::after{top:0.3rem;}

/* Badge */
.badge{
    font-weight:700; line-height:1;
    border-radius:1rem; 
}

/* Help text */
.help-text{margin-top:-1rem; margin-bottom:1.5rem;}

/* Tooltip */
.tooltip{max-width:16rem !important;}

/* Tabs */
.tabs{
    margin-top:-.1rem;
    background-color:darken($white, 3%); border:0;
}

.tabs-title{
    border-top:.3rem solid transparent;
    &.is-active{
        border-top:.3rem solid $primary-color;
        .tabs-title-anchor{color:$body-font-color;}        
    }
    .tabs-title-anchor{font-size:1.4rem;}    
}

/* Media query min-width: 768px */
@include breakpoint(large) {

    /* Header top bar */
    .header-wrapper{
        .menu-anchor{
            height:6rem;
            &.main-menu-anchor{line-height:3.5rem;}
        }
        .is-down-arrow{
            &.is-dropdown-submenu-parent .menu-anchor::after{top:2.8rem;}
        }
    }

    .top-bar-right{
        padding-left:25rem;
        top:0;
    }

}

/* Media query min-width: 1024px */
@include breakpoint(xlarge) {
    
    /* Buttons */
    .button-wrapper{
        .dropdown.menu{
            float:right; 
            .is-dropdown-submenu.first-sub{left:auto; right:0;}
        }
    }

}