// Mixins
// =========================

// Opacity
// -------------------------

@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=#{$opacity * 100}); // IE8
}


// Sizing shortcuts
// -------------------------

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}
