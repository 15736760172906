/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Import all Foundation components
@import '../../../../../../../../../../bower_components/foundation-sites/scss/foundation.scss';

// Import settings_frontend.scss. For overwriting foundation variables
@import 'settings';

// Include the right mixins
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
// @include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

// Import perfect-scrollbar files
@import '../../../../../../../../../../public/assets/lib/perfect_scrollbar/scss/main.scss';

// Import Cropper
@import "../../../../../../../../../../bower_components/cropper/src/scss/cropper.scss";

// Import custom style
@import 'admin';