//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Off-canvas
//  24. Orbit
//  25. Pagination
//  26. Progress Bar
//  27. Reveal
//  28. Slider
//  29. Switch
//  30. Table
//  31. Tabs
//  32. Thumbnail
//  33. Title Bar
//  34. Tooltip
//  35. Top Bar
//  
//  Custom
//  36. Alert boxes (Foundation 5)

//@import 'util/util';

// 1. Global
// ---------

$global-font-size: 62.5%;
$global-width: rem-calc(1200);
// $global-lineheight: 1.5;
$primary-color: $support-color-admin; // #2199e8 !default
$secondary-color: #737373;
$success-color: #00a65a;
$warning-color: #f39c12;
$alert-color: #dd4b39;
$light-gray: #f3f3f3;
$medium-gray: #dddddd;
$dark-gray: #373737;
// $black: #0a0a0a;
// $white: #fefefe;
$body-background: $light-gray;
$body-font-color: #555555;
$body-font-family: 'Roboto', Helvetica, Roboto, Arial, sans-serif;
// $body-antialiased: true;
$global-margin: 1.5rem;
$global-padding: 1.5rem;
// $global-weight-normal: normal;
// $global-weight-bold: bold;
// $global-radius: 0;
// $global-text-direction: ltr;

// Internal map used to iterate through colors, to generate CSS classes with less code
$foundation-colors: (
    primary: $primary-color,
    secondary: $secondary-color,
    success: $success-color,
    alert: $alert-color,
    warning: $warning-color,
);

// 2. Breakpoints
// --------------

$breakpoints: (
    small: 0,
    medium: 480px,
    large: 768px,
    xlarge: 1024px,
    xxlarge: 1200px,
); 
$breakpoint-classes: (small medium large xlarge xxlarge);

// 3. The Grid
// -----------

$grid-row-width: 120rem;
// $grid-column-count: 12;
// $grid-column-responsive-gutter: (
//   small: 20px,
//   medium: 30px,
// );
// $grid-column-align-edge: true;
// $block-grid-max: 8;

// 4. Base Typography
// ------------------

// $header-font-family: $body-font-family;
// $header-font-weight: $global-weight-normal;
// $header-font-style: normal;
// $font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-sizes: (
    small: (
        'h1': 24,
        'h2': 20,
        'h3': 19,
        'h4': 18,
        'h5': 17,
        'h6': 16,
    )
);
$header-color: $dark-gray;
$header-lineheight: 1.2;
$header-margin-bottom: 1.6rem;
// $header-text-rendering: optimizeLegibility;
// $small-font-size: 80%;
$header-small-font-color: lighten($header-color, 35%);
// $paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 2rem;
// $paragraph-text-rendering: optimizeLegibility;
// $code-color: $black;
// $code-font-family: $font-family-monospace;
// $code-font-weight: $global-weight-normal;
// $code-background: $light-gray;
// $code-border: 1px solid $medium-gray;
// $code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
// $anchor-text-decoration: none;
// $anchor-text-decoration-hover: none;
// $hr-width: $global-width;
// $hr-border: 1px solid $medium-gray;
// $hr-margin: rem-calc(20) auto;
// $list-lineheight: $paragraph-lineheight;
// $list-margin-bottom: $paragraph-margin-bottom;
// $list-style-type: disc;
// $list-style-position: outside;
// $list-side-margin: 1.25rem;
// $list-nested-side-margin: 1.25rem;
// $defnlist-margin-bottom: 1rem;
// $defnlist-term-weight: $global-weight-bold;
// $defnlist-term-margin-bottom: 0.3rem;
// $blockquote-color: $dark-gray;
// $blockquote-padding: rem-calc(9 20 0 19);
// $blockquote-border: 1px solid $medium-gray;
// $cite-font-size: rem-calc(13);
// $cite-color: $dark-gray;
// $keystroke-font: $font-family-monospace;
// $keystroke-color: $black;
// $keystroke-background: $light-gray;
// $keystroke-padding: rem-calc(2 4 0);
// $keystroke-radius: $global-radius;
// $abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

// $lead-font-size: $global-font-size * 1.25;
// $lead-lineheight: 1.6;
// $subheader-lineheight: 1.4;
// $subheader-color: $dark-gray;
// $subheader-font-weight: $global-weight-normal;
// $subheader-margin-top: 0.2rem;
// $subheader-margin-bottom: 0.5rem;
// $stat-font-size: 2.5rem;

// 6. Abide
// --------

// $abide-inputs: true;
// $abide-labels: true;
// $input-background-invalid: $alert-color;
// $form-label-color-invalid: $alert-color;
// $input-error-color: $alert-color;
// $input-error-font-size: rem-calc(12);
// $input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

// $accordion-background: $white;
// $accordion-plusminus: true;
// $accordion-item-color: foreground($accordion-background, $primary-color);
// $accordion-item-background-hover: $light-gray;
// $accordion-item-padding: 1.25rem 1rem;
// $accordion-content-background: $white;
// $accordion-content-border: 1px solid $light-gray;
// $accordion-content-color: foreground($accordion-background, $primary-color);
// $accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: false;
$accordionmenu-arrow-color: $white;

// 9. Badge
// --------

$badge-background: $primary-color;
// $badge-color: foreground($badge-background);
$badge-padding: 0.3em 0.7rem;
$badge-minwidth: 1rem;
$badge-font-size: 1.15rem;

// 10. Breadcrumbs
// ---------------

// $breadcrumbs-margin: 0 0 $global-margin 0;
// $breadcrumbs-item-font-size: rem-calc(11);
// $breadcrumbs-item-color: $primary-color;
// $breadcrumbs-item-color-current: $black;
// $breadcrumbs-item-color-disabled: $medium-gray;
// $breadcrumbs-item-margin: 0.75rem;
// $breadcrumbs-item-uppercase: true;
// $breadcrumbs-item-slash: true;

// 11. Button
// ----------

$button-padding: .5em 1em;
// $button-margin: 0 0 $global-margin 0;
// $button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($primary-color, $lightness: -15%);
// $button-color: #fff;
// $button-color-alt: #000;
// $button-radius: $global-radius;
$button-sizes: (
    tiny: 1.1rem,
    small: 1.4rem,
    default: 1.6rem,
    large: 1.8rem,
);
$button-opacity-disabled: 1;

// 12. Button Group
// ----------------

// $buttongroup-margin: 1rem;
$buttongroup-spacing: .5rem;
// $buttongroup-child-selector: '.button';
// $buttongroup-expand-max: 6;

// 13. Callout
// -----------

// $callout-background: $white;
// $callout-background-fade: 85%;
// $callout-border: 1px solid rgba($black, 0.25);
// $callout-margin: 0 0 1rem 0;
// $callout-padding: 1rem;
// $callout-font-color: $body-font-color;
// $callout-font-color-alt: $body-background;
// $callout-radius: $global-radius;
// $callout-link-tint: 30%;

// 14. Close Button
// ----------------

// $closebutton-position: right top;
// $closebutton-offset-horizontal: 1rem;
// $closebutton-offset-vertical: 0.5rem;
// $closebutton-size: 2em;
// $closebutton-lineheight: 1;
// $closebutton-color: $dark-gray;
// $closebutton-color-hover: $black;

// 15. Drilldown
// -------------

// $drilldown-transition: transform 0.15s linear;
// $drilldown-arrows: true;
// $drilldown-arrow-color: $primary-color;
// $drilldown-background: $white;

// 16. Dropdown
// ------------

// $dropdown-padding: 1rem;
// $dropdown-border: 1px solid $medium-gray;
// $dropdown-font-size: 16rem;
// $dropdown-width: 300px;
// $dropdown-radius: $global-radius;
// $dropdown-sizes: (
//   tiny: 100px,
//   small: 200px,
//   large: 400px,
// );

// 17. Dropdown Menu
// -----------------

$dropdownmenu-arrows: false;
$dropdownmenu-arrow-color: $white;
// $dropdownmenu-min-width: 200px;
$dropdownmenu-background: $dark-gray; 
$dropdownmenu-border: 0;

// 18. Flex Video
// --------------

// $flexvideo-margin-bottom: rem-calc(16);
// $flexvideo-ratio: 4 by 3;
// $flexvideo-ratio-widescreen: 16 by 9;

// 19. Forms
// ---------

// $fieldset-border: 1px solid $medium-gray;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);
// $legend-padding: rem-calc(0 3);
$form-spacing: 1.4rem;
$helptext-color: $body-font-color;
$helptext-font-size: 1.1rem;
$helptext-font-style: normal;
$input-prefix-color: lighten($body-font-color, 15%);
$input-prefix-background: lighten($light-gray, 0.5%);
// $input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1.4rem;
$form-label-color: $body-font-color;
$form-label-font-size: 1.4rem;
$form-label-font-weight: 500;
$form-label-line-height: $global-lineheight;
// $select-background: $white;
// $select-triangle-color: #333;
// $select-radius: $global-radius;
$input-color: $body-font-color;
// $input-font-family: inherit;
$input-font-size: 1.4rem;
// $input-background: $white;
// $input-background-focus: $white;
// $input-background-disabled: $light-gray;
// $input-border: 1px solid $medium-gray;
// $input-border-focus: 1px solid $dark-gray;
$input-shadow: none;
$input-shadow-focus: none;
// $input-cursor-disabled: default;
// $input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
// $input-number-spinners: true;
// $input-radius: $global-radius;

// 20. Label
// ---------

// $label-background: $primary-color;
// $label-color: foreground($label-background);
$label-font-size: 1.2rem;
// $label-padding: 0.33333rem 0.5rem;
// $label-radius: $global-radius;

// 21. Media Object
// ----------------

// $mediaobject-margin-bottom: $global-margin;
// $mediaobject-section-padding: $global-padding;
// $mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------

// $menu-margin: 0;
// $menu-margin-nested: 1rem;
$menu-item-padding: 1.2rem $global-padding;
// $menu-icon-spacing: 0.25rem;
// $menu-expand-max: 6;

// 23. Off-canvas
// --------------

// $offcanvas-size: 250px;
// $offcanvas-background: $light-gray;
// $offcanvas-zindex: -1;
// $offcanvas-transition-length: 0.5s;
// $offcanvas-transition-timing: ease;
// $offcanvas-fixed-reveal: true;
// $offcanvas-exit-background: rgba($white, 0.25);
// $maincontent-class: 'off-canvas-content';
// $maincontent-shadow: 0 0 10px rgba($black, 0.5);

// 24. Orbit
// ---------

// $orbit-bullet-background: $medium-gray;
// $orbit-bullet-background-active: $dark-gray;
// $orbit-bullet-diameter: 1.2rem;
// $orbit-bullet-margin: 0.1rem;
// $orbit-bullet-margin-top: 0.8rem;
// $orbit-bullet-margin-bottom: 0.8rem;
// $orbit-caption-background: rgba($black, 0.5);
// $orbit-caption-padding: 1rem;
// $orbit-control-background-hover: rgba($black, 0.5);
// $orbit-control-padding: 1rem;
// $orbit-control-zindex: 10;

// 25. Pagination
// --------------

$pagination-font-size: 1.4rem;
// $pagination-margin-bottom: $global-margin;
$pagination-item-color: $body-font-color;
// $pagination-item-padding: rem-calc(3 10);
// $pagination-item-spacing: rem-calc(1);
// $pagination-radius: $global-radius;
// $pagination-item-background-hover: $light-gray;
// $pagination-item-background-current: $primary-color;
// $pagination-item-color-current: foreground($pagination-item-background-current);
// $pagination-item-color-disabled: $medium-gray;
// $pagination-ellipsis-color: $black;
// $pagination-mobile-items: false;
// $pagination-arrows: true;

// 26. Progress Bar
// ----------------

// $progress-height: 1rem;
// $progress-background: $medium-gray;
// $progress-margin-bottom: $global-margin;
// $progress-meter-background: $primary-color;
// $progress-radius: $global-radius;

// 27. Reveal
// ----------

// $reveal-background: $white;
// $reveal-width: 600px;
// $reveal-max-width: $global-width;
// $reveal-offset: rem-calc(100);
$reveal-padding: ($global-padding * 2);
// $reveal-border: 1px solid $medium-gray;
// $reveal-radius: $global-radius;
// $reveal-zindex: 1005;
// $reveal-overlay-background: rgba($black, 0.45);

// 28. Slider
// ----------

// $slider-height: 0.5rem;
// $slider-width-vertical: $slider-height;
// $slider-background: $light-gray;
// $slider-fill-background: $medium-gray;
// $slider-handle-height: 1.4rem;
// $slider-handle-width: 1.4rem;
// $slider-handle-background: $primary-color;
// $slider-opacity-disabled: 0.25;
// $slider-radius: $global-radius;
// $slider-transition: all 0.2s ease-in-out;

// 29. Switch
// ----------

// $switch-background: $medium-gray;
$switch-background-active: $primary-color;
// $switch-height: 2rem;
// $switch-height-tiny: 1.5rem;
// $switch-height-small: 1.75rem;
$switch-height-large: 2.4rem;
// $switch-radius: $global-radius;
// $switch-margin: $global-margin;
// $switch-paddle-background: $white;
$switch-paddle-offset: 0.3rem;
// $switch-paddle-radius: $global-radius;
// $switch-paddle-transition: all 0.25s ease-out;

// 30. Table
// ---------

// $table-background: $white;
// $table-color-scale: 5%;
// $table-border: 1px solid smart-scale($table-background, $table-color-scale);
// $table-padding: rem-calc(8 10 10);
// $table-hover-scale: 2%;
// $table-row-hover: darken($table-background, $table-hover-scale);
// $table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
// $table-striped-background: smart-scale($table-background, $table-color-scale);
// $table-stripe: even;
// $table-head-background: smart-scale($table-background, $table-color-scale / 2);
// $table-foot-background: smart-scale($table-background, $table-color-scale);
// $table-head-font-color: $body-font-color;
// $show-header-for-stacked: false;

// 31. Tabs
// --------

// $tab-margin: 0;
// $tab-background: $white;
$tab-background-active: $white;
// $tab-border: $light-gray;
$tab-item-color: $primary-color;
// $tab-item-background-hover: $white;
$tab-item-padding: $global-padding $global-padding 1.8rem $global-padding;
// $tab-expand-max: 6;
// $tab-content-background: $white;
$tab-content-border: transparent;
// $tab-content-color: foreground($tab-background, $primary-color);
$tab-content-padding: 1rem 0 0 0;

// 32. Thumbnail
// -------------

// $thumbnail-border: solid 4px $white;
// $thumbnail-margin-bottom: $global-margin;
// $thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
// $thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
// $thumbnail-transition: box-shadow 200ms ease-out;
// $thumbnail-radius: $global-radius;

// 33. Title Bar
// -------------

// $titlebar-background: $black;
// $titlebar-color: $white;
// $titlebar-padding: 0.5rem;
// $titlebar-text-font-weight: bold;
// $titlebar-icon-color: $white;
// $titlebar-icon-color-hover: $medium-gray;
// $titlebar-icon-spacing: 0.25rem;

// 34. Tooltip
// -----------

// $tooltip-background-color: $black;
// $tooltip-color: $white;
// $tooltip-padding: 0.75rem;
$tooltip-font-size: 1.2rem;
// $tooltip-pip-width: 0.75rem;
// $tooltip-pip-height: $tooltip-pip-width * 0.866;
// $tooltip-pip-offset: 1.25rem;
// $tooltip-radius: $global-radius;

// 35. Top Bar
// -----------

$topbar-padding: 0;
$topbar-background: none;
// $topbar-title-spacing: 1rem;
// $topbar-input-width: 200px;

//  36. Alert boxes (Foundation 5)
// -----------
 
$alert-padding-top: rem-calc(14);
$alert-padding-default-float: $alert-padding-top;
$alert-padding-opposite-direction: $alert-padding-top + rem-calc(10);
$alert-padding-bottom: $alert-padding-top;

$alert-font-weight: normal;
$alert-font-size: rem-calc(14);
$alert-font-color: $white;
$alert-font-color-alt: scale-color($secondary-color, $lightness: -66%);

$alert-function-factor: -14%;

$alert-border-style: solid;
$alert-border-width: 1px;
$alert-border-color: scale-color($primary-color, $lightness: $alert-function-factor);
$alert-bottom-margin: rem-calc(20);

$alert-close-color: $white;
$alert-close-top: 50%;
$alert-close-position: rem-calc(4);
$alert-close-font-size: rem-calc(22);
$alert-close-opacity: 0.8;
$alert-close-opacity-hover: 1;
$alert-close-padding: 0 6px 4px;
$alert-close-background: inherit;

$alert-radius: .5rem;

$alert-transition-speed: 300ms;
$alert-transition-ease: ease-out;