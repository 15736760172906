/*
 * CUSTOM SCSS
 *
 * Should you need to customize the admin, this is the file to do it!
 * Also, if you'd like, you can change the supporting color of the admin
 * by replacing the hex color code of $support-color-admin variable
 */

/* Support color of the admin */
$support-color-admin: #00498e;

/* All general style of the admin */
@import '../../../../vendor/click/clickadmin/src/Click/ClickAdmin/resources/assets/sass/backend/app';

// Import select2 
@import '../../../../public/assets/lib/select2/scss/core';

/* Place all your custom styles below */
.select2-container--default .select2-selection--single {
    height: 3.4rem;
    padding: 0.7rem;
    border: 1px solid #cacaca;
    margin: 0 0 1.4rem;
    font-size: 1.4rem;
    font-family: inherit;
    line-height: normal;
    color: #555555;
    background-color: #fefefe;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2851, 51, 51%29"></polygon></svg>');
    border-radius: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-size: 9px 6px;
    background-position: right center;
    background-origin: content-box;
    background-repeat: no-repeat;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-image {
    vertical-align: top;
    width: 66px;
    margin-right: 1rem;
}

.select2-content {
    display: inline-block;
    margin-bottom: 0;
    span {
        display: block;
    }
}

.select2-dropdown {
    border: 1px solid #cacaca;
}

.select2-container--open .select2-dropdown {
    left: 0;
    top: -1.3rem;
}

.select2-results__options {
    min-height: 315px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: normal !important;
    padding-left: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #555555;
}

.select2-container {
    width: 100% !important;
}